<template>
  <div class="AiLocation" :class="[` AiLocation--${iconPosition}`]">
    <ai-icon
      class="AiLocation-icon"
      :class="[` AiLocation-icon--${variant}`]"
      :size="iconSize"
      :name="iconUse as IconName"
      :description="$t('booking.recap.icons.location')" />
    <ai-typo
      as="p"
      :class="[`AiLocation-title AiLocation-title--${variant}`]"
      class="AiLocation-title"
      :variant="typoVariant">
      {{ label }}
    </ai-typo>
  </div>
</template>

<script setup lang="ts">
import AiIcon from '../AiIcon/AiIcon.vue';
import AiTypo from '../AiTypo/AiTypo.vue';
import type { IconName } from '../AiIcon/types';
import type { AiTypoVariant } from '../AiTypo/types';

export interface Props {
  country?: string | null;
  city?: string | null;
  mobileIcon?: boolean;
  address?: string | null;
  iconPosition?: 'top' | 'center' | 'end';
  typoVariant?: AiTypoVariant;
  variant?: 'positive' | 'inverse';
}

const props = withDefaults(defineProps<Props>(), {
  mobileIcon: false,
  variant: 'positive',
  city: null,
  iconPosition: 'center',
  typoVariant: 'overline-01',
  country: null,
  address: null,
});

const isSmallDevice = useMatchMediaQuery('tablet');

const iconUse = computed(() =>
  isSmallDevice.value && props.mobileIcon ? 'location-mobile' : 'location',
);

const label = computed(() =>
  props.city && props.country
    ? `${props.city.toUpperCase()}, ${props.country.toUpperCase()}`
    : props.address,
);

const iconSize = computed(() => (isSmallDevice.value ? 18 : 24));
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';

.AiLocation {
  display: flex;
  align-items: center;

  &--top {
    align-items: start;
  }

  &--end {
    align-items: flex-end;
  }

  &-title {
    color: colors.$stratos-900;
    &--inverse {
      color: colors.$white;
    }
  }

  &-icon {
    margin-right: constants.$inner-02;
    color: colors.$stratos-600;
    &--inverse {
      color: colors.$gold-500;
    }
  }
}
</style>
